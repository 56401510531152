import React, { useEffect } from "react"
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import  { Dashboardee } from "./DashboardUser.component";
import DashboardComponent, {  } from "./User.component";
import AttendanceReport from "../Timer/AttendanceReport.component";
import { Box, Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { GoArrowDownLeft } from "react-icons/go";

export const DashboardAllUser = () => {
    // let id = useParams()
    // const { userId } = id
    // const role = sessionStorage.getItem("userType")
    // const Id = sessionStorage.getItem("userId")
   
const actions = [
{
    icon: <svg width="80" height="69" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2073_1225)">
        <path d="M83.2324 12.6562C83.0342 12.6562 82.7021 12.6561 82.2656 12.6558V14.7656C82.2656 17.1899 81.3026 19.5148 79.5884 21.229C77.8742 22.9432 75.5492 23.9062 73.125 23.9062C70.7008 23.9062 68.3758 22.9432 66.6616 21.229C64.9474 19.5148 63.9844 17.1899 63.9844 14.7656V12.6369C52.4359 12.6241 37.8079 12.607 26.0156 12.5932V14.7656C26.0156 17.1899 25.0526 19.5148 23.3384 21.229C21.6242 22.9432 19.2992 23.9062 16.875 23.9062C14.4508 23.9062 12.1258 22.9432 10.4116 21.229C8.6974 19.5148 7.73438 17.1899 7.73438 14.7656V12.5715C6.05115 12.5695 5.09766 12.5684 5.09766 12.5684C5.09766 12.5684 1.58203 12.6562 1.58203 16.084V26.8066H88.418V15.293C88.418 15.293 87.8906 12.6562 83.2324 12.6562Z" fill="#002046" />
        <path opacity="0.3" d="M26.0156 12.6562H63.9844V15.4688H26.0156V12.6562Z" fill="#002046" />
        <path d="M88.0664 26.8945V49.4789C85.0072 45.2842 80.6991 42.1649 75.7591 40.5678C70.819 38.9707 65.5003 38.9776 60.5644 40.5875C55.6285 42.1974 51.3286 45.3278 48.2802 49.5305C45.2319 53.7331 43.5914 58.7926 43.5938 63.9844C43.5912 65.6378 43.7585 67.287 44.093 68.9062C44.1844 69.3773 44.2969 69.8484 44.4164 70.3125C44.4797 70.5445 44.55 70.7836 44.6203 71.0156C44.6334 71.0751 44.6499 71.1338 44.6695 71.1914H1.40625V26.8945H88.0664Z" fill="#F1F7FF" />
        <path d="M41.4844 33.0469H30.2344C29.8617 33.048 29.5047 33.1965 29.2412 33.46C28.9777 33.7234 28.8292 34.0805 28.8281 34.4531V45.7031C28.8292 46.0757 28.9777 46.4328 29.2412 46.6963C29.5047 46.9598 29.8618 47.1083 30.2344 47.1094H41.4844C41.857 47.1083 42.214 46.9598 42.4775 46.6963C42.741 46.4328 42.8895 46.0757 42.8906 45.7031V34.4531C42.8895 34.0805 42.741 33.7234 42.4775 33.46C42.2141 33.1965 41.857 33.048 41.4844 33.0469ZM40.0781 44.2969H31.6406V35.8594H40.0781V44.2969Z" fill="#002046" />
        <path d="M31.6406 35.8594H40.0781V44.2969H31.6406V35.8594Z" fill="#E1EBF4" />
        <path d="M41.4844 51.3281H30.2344C29.8617 51.3292 29.5047 51.4777 29.2412 51.7412C28.9777 52.0047 28.8292 52.3617 28.8281 52.7344V63.9844C28.8292 64.357 28.9777 64.714 29.2412 64.9775C29.5047 65.241 29.8618 65.3895 30.2344 65.3906H41.4844C41.857 65.3895 42.214 65.241 42.4775 64.9775C42.741 64.714 42.8895 64.357 42.8906 63.9844V52.7344C42.8895 52.3617 42.741 52.0047 42.4775 51.7412C42.2141 51.4777 41.857 51.3292 41.4844 51.3281ZM40.0781 62.5781H31.6406V54.1406H40.0781V62.5781Z" fill="#002046" />
        <path d="M31.6406 54.1406H40.0781V62.5781H31.6406V54.1406Z" fill="#E1EBF4" />
        <path d="M23.2031 51.3281H11.9531C11.5805 51.3292 11.2234 51.4777 10.96 51.7412C10.6965 52.0047 10.548 52.3617 10.5469 52.7344V63.9844C10.548 64.357 10.6965 64.714 10.96 64.9775C11.2235 65.241 11.5805 65.3895 11.9531 65.3906H23.2031C23.5757 65.3895 23.9328 65.241 24.1963 64.9775C24.4598 64.714 24.6083 64.357 24.6094 63.9844V52.7344C24.6083 52.3617 24.4598 52.0047 24.1963 51.7412C23.9328 51.4777 23.5758 51.3292 23.2031 51.3281ZM21.7969 62.5781H13.3594V54.1406H21.7969V62.5781Z" fill="#002046" />
        <path d="M13.3594 54.1406H21.7969V62.5781H13.3594V54.1406Z" fill="#E1EBF4" />
        <path d="M26.0156 11.25H63.9844V14.0625H26.0156V11.25Z" fill="#002046" />
        <path d="M73.125 4.21875C71.4474 4.22127 69.8393 4.88879 68.6531 6.07499C67.4669 7.2612 66.7994 8.86932 66.7969 10.5469V14.7656C66.7969 16.4439 67.4636 18.0535 68.6503 19.2403C69.8371 20.427 71.4467 21.0938 73.125 21.0938C74.8033 21.0938 76.4129 20.427 77.5997 19.2403C78.7864 18.0535 79.4531 16.4439 79.4531 14.7656V10.5469C79.4506 8.86932 78.7831 7.2612 77.5969 6.07499C76.4107 4.88879 74.8026 4.22127 73.125 4.21875ZM76.6406 14.7656C76.6406 15.698 76.2702 16.5922 75.6109 17.2515C74.9516 17.9109 74.0574 18.2812 73.125 18.2812C72.1926 18.2812 71.2984 17.9109 70.6391 17.2515C69.9798 16.5922 69.6094 15.698 69.6094 14.7656V10.5469C69.6094 9.61447 69.9798 8.72026 70.6391 8.06095C71.2984 7.40165 72.1926 7.03125 73.125 7.03125C74.0574 7.03125 74.9516 7.40165 75.6109 8.06095C76.2702 8.72026 76.6406 9.61447 76.6406 10.5469V14.7656Z" fill="#002046" />
        <path d="M76.6406 10.5469V14.7656C76.6406 15.698 76.2702 16.5922 75.6109 17.2515C74.9516 17.9109 74.0574 18.2812 73.125 18.2812C72.1926 18.2812 71.2984 17.9109 70.6391 17.2515C69.9798 16.5922 69.6094 15.698 69.6094 14.7656V10.5469C69.6094 9.61447 69.9798 8.72026 70.6391 8.06095C71.2984 7.40165 72.1926 7.03125 73.125 7.03125C74.0574 7.03125 74.9516 7.40165 75.6109 8.06095C76.2702 8.72026 76.6406 9.61447 76.6406 10.5469Z" fill="#E1EBF4" />
        <path d="M16.875 4.21875C15.1974 4.22127 13.5893 4.88879 12.4031 6.07499C11.2169 7.2612 10.5494 8.86932 10.5469 10.5469V14.7656C10.5469 16.4439 11.2136 18.0535 12.4003 19.2403C13.5871 20.427 15.1967 21.0938 16.875 21.0938C18.5533 21.0938 20.1629 20.427 21.3497 19.2403C22.5364 18.0535 23.2031 16.4439 23.2031 14.7656V10.5469C23.2006 8.86932 22.5331 7.2612 21.3469 6.07499C20.1607 4.88879 18.5526 4.22127 16.875 4.21875ZM20.3906 14.7656C20.3906 15.698 20.0202 16.5922 19.3609 17.2515C18.7016 17.9109 17.8074 18.2812 16.875 18.2812C15.9426 18.2812 15.0484 17.9109 14.3891 17.2515C13.7298 16.5922 13.3594 15.698 13.3594 14.7656V10.5469C13.3594 9.61447 13.7298 8.72026 14.3891 8.06095C15.0484 7.40165 15.9426 7.03125 16.875 7.03125C17.8074 7.03125 18.7016 7.40165 19.3609 8.06095C20.0202 8.72026 20.3906 9.61447 20.3906 10.5469V14.7656Z" fill="#002046" />
        <path d="M20.3906 10.5469V14.7656C20.3906 15.698 20.0202 16.5922 19.3609 17.2515C18.7016 17.9109 17.8074 18.2812 16.875 18.2812C15.9426 18.2812 15.0484 17.9109 14.3891 17.2515C13.7298 16.5922 13.3594 15.698 13.3594 14.7656V10.5469C13.3594 9.61447 13.7298 8.72026 14.3891 8.06095C15.0484 7.40165 15.9426 7.03125 16.875 7.03125C17.8074 7.03125 18.7016 7.40165 19.3609 8.06095C20.0202 8.72026 20.3906 9.61447 20.3906 10.5469Z" fill="#E1EBF4" />
        <path d="M61.1719 40.4039V34.4531C61.1719 34.0802 61.0237 33.7225 60.76 33.4588C60.4963 33.1951 60.1386 33.0469 59.7656 33.0469H48.5156C48.1427 33.0469 47.785 33.1951 47.5213 33.4588C47.2576 33.7225 47.1094 34.0802 47.1094 34.4531V45.7031C47.1094 46.0761 47.2576 46.4338 47.5213 46.6975C47.785 46.9612 48.1427 47.1094 48.5156 47.1094H50.3157C51.2804 46.0845 52.3305 45.1437 53.4548 44.2969H49.9219V35.8594H58.3594V41.436C59.2749 41.0347 60.2141 40.6901 61.1719 40.4039Z" fill="#002046" />
        <path d="M79.4531 42.1083V34.4531C79.4531 34.0802 79.3049 33.7225 79.0412 33.4588C78.7775 33.1951 78.4198 33.0469 78.0469 33.0469H66.7969C66.4239 33.0469 66.0662 33.1951 65.8025 33.4588C65.5388 33.7225 65.3906 34.0802 65.3906 34.4531V39.5405C66.3242 39.432 67.2632 39.3767 68.2031 39.375V35.8594H76.6406V40.8655C77.6033 41.2204 78.5426 41.6354 79.4531 42.1083Z" fill="#002046" />
        <path d="M84.375 11.25H82.2656V14.0625H84.375C85.1207 14.0633 85.8356 14.3599 86.3629 14.8871C86.8901 15.4144 87.1867 16.1293 87.1875 16.875V25.3125H2.8125V16.875C2.81329 16.1293 3.10987 15.4144 3.63714 14.8871C4.16441 14.3599 4.87932 14.0633 5.625 14.0625H7.73438V11.25H5.625C4.13368 11.2517 2.70393 11.8449 1.64941 12.8994C0.594887 13.9539 0.00170649 15.3837 0 16.875L0 67.5C0.00170649 68.9913 0.594887 70.4211 1.64941 71.4756C2.70393 72.5301 4.13368 73.1233 5.625 73.125H45.3612C44.9916 72.2074 44.6781 71.2682 44.4225 70.3125H5.625C4.87932 70.3117 4.16441 70.0151 3.63714 69.4879C3.10987 68.9606 2.81329 68.2457 2.8125 67.5V28.125H87.1875V48.3418C88.2683 49.6539 89.2108 51.0742 90 52.5799V16.875C89.9983 15.3837 89.4051 13.9539 88.3506 12.8994C87.2961 11.8449 85.8663 11.2517 84.375 11.25Z" fill="#002046" />
        <path d="M68.2031 42.1875C63.8921 42.1875 59.6779 43.4659 56.0934 45.8609C52.509 48.256 49.7152 51.6602 48.0654 55.6431C46.4157 59.6259 45.984 64.0086 46.8251 68.2367C47.6661 72.4649 49.7421 76.3487 52.7904 79.3971C55.8388 82.4454 59.7226 84.5214 63.9508 85.3624C68.179 86.2035 72.5616 85.7718 76.5444 84.1221C80.5273 82.4723 83.9315 79.6786 86.3266 76.0941C88.7216 72.5096 90 68.2954 90 63.9844C89.9927 58.2057 87.6938 52.6659 83.6077 48.5798C79.5216 44.4937 73.9818 42.1949 68.2031 42.1875ZM68.2031 82.9688C64.4484 82.9688 60.7779 81.8553 57.656 79.7693C54.534 77.6833 52.1007 74.7183 50.6639 71.2494C49.227 67.7804 48.851 63.9633 49.5835 60.2807C50.3161 56.5981 52.1241 53.2154 54.7792 50.5604C57.4342 47.9054 60.8169 46.0973 64.4995 45.3648C68.1821 44.6323 71.9992 45.0082 75.4681 46.4451C78.9371 47.882 81.902 50.3153 83.9881 53.4372C86.0741 56.5592 87.1875 60.2296 87.1875 63.9844C87.1823 69.0178 85.1805 73.8435 81.6214 77.4026C78.0623 80.9618 73.2365 82.9636 68.2031 82.9688Z" fill="#002046" />
        <path d="M87.1875 63.9844C87.1875 67.7391 86.0741 71.4096 83.9881 74.5315C81.902 77.6535 78.9371 80.0868 75.4681 81.5237C71.9992 82.9605 68.1821 83.3365 64.4995 82.604C60.8169 81.8715 57.4342 80.0634 54.7792 77.4084C52.1241 74.7533 50.3161 71.3707 49.5835 67.688C48.851 64.0054 49.227 60.1883 50.6639 56.7194C52.1007 53.2504 54.534 50.2855 57.656 48.1994C60.7779 46.1134 64.4484 45 68.2031 45C73.2365 45.0052 78.0623 47.007 81.6214 50.5661C85.1805 54.1252 87.1823 58.951 87.1875 63.9844Z" fill="#00CF7F" />
        <path d="M77.7544 55.2568L63.3218 69.7275L57.2457 63.6356C56.982 63.3726 56.6248 63.225 56.2525 63.225C55.8801 63.225 55.5229 63.3727 55.2593 63.6357C54.9957 63.8987 54.8471 64.2555 54.8462 64.6279C54.8453 65.0003 54.9921 65.3578 55.2544 65.6221L62.3262 72.712C62.592 72.9727 62.9495 73.1187 63.3218 73.1187C63.6941 73.1187 64.0516 72.9727 64.3174 72.712L79.7456 57.2433C80.008 56.979 80.1548 56.6214 80.1539 56.2491C80.153 55.8767 80.0044 55.5199 79.7408 55.2569C79.4771 54.9939 79.12 54.8462 78.7476 54.8462C78.3752 54.8462 78.0181 54.9938 77.7544 55.2568Z" fill="#002046" />
        <path d="M58.3594 35.8594V41.4352C57.3924 41.8611 56.4531 42.3472 55.5469 42.8906C54.825 43.3234 54.1256 43.7928 53.4516 44.2969H49.9219V35.8594H58.3594Z" fill="#E1EBF4" />
        <path d="M76.6406 35.8594H68.2031V39.375C71.0814 39.3723 73.9375 39.8768 76.6406 40.8655V35.8594Z" fill="#E1EBF4" />
        <path opacity="0.5" d="M76.6406 10.5469V11.9531C76.6406 11.0207 76.2702 10.1265 75.6109 9.4672C74.9516 8.8079 74.0574 8.4375 73.125 8.4375C72.1926 8.4375 71.2984 8.8079 70.6391 9.4672C69.9798 10.1265 69.6094 11.0207 69.6094 11.9531V10.5469C69.6094 9.61447 69.9798 8.72026 70.6391 8.06095C71.2984 7.40165 72.1926 7.03125 73.125 7.03125C74.0574 7.03125 74.9516 7.40165 75.6109 8.06095C76.2702 8.72026 76.6406 9.61447 76.6406 10.5469Z" fill="white" />
        <path opacity="0.5" d="M20.3906 10.5469V11.9531C20.3906 11.0207 20.0202 10.1265 19.3609 9.4672C18.7016 8.8079 17.8074 8.4375 16.875 8.4375C15.9426 8.4375 15.0484 8.8079 14.3891 9.4672C13.7298 10.1265 13.3594 11.0207 13.3594 11.9531V10.5469C13.3594 9.61447 13.7298 8.72026 14.3891 8.06095C15.0484 7.40165 15.9426 7.03125 16.875 7.03125C17.8074 7.03125 18.7016 7.40165 19.3609 8.06095C20.0202 8.72026 20.3906 9.61447 20.3906 10.5469Z" fill="white" />
        <path opacity="0.45" d="M44.4164 70.3125H5.625C5.25563 70.3126 4.88986 70.2399 4.54858 70.0986C4.20731 69.9573 3.89722 69.7501 3.63604 69.489C3.37485 69.2278 3.16769 68.9177 3.02638 68.5764C2.88508 68.2351 2.8124 67.8694 2.8125 67.5V66.0938C2.8124 66.4631 2.88508 66.8289 3.02638 67.1702C3.16769 67.5114 3.37485 67.8215 3.63604 68.0827C3.89722 68.3439 4.20731 68.5511 4.54858 68.6924C4.88986 68.8337 5.25563 68.9064 5.625 68.9062H44.093C44.1844 69.3773 44.2969 69.8484 44.4164 70.3125Z" fill="#00BCFF" />
        <path opacity="0.5" d="M2.8125 23.9062H87.1875V25.3125H2.8125V23.9062Z" fill="white" />
        <path opacity="0.3" d="M7.73438 14.0625V15.4688H5.625C5.25563 15.4686 4.88985 15.5413 4.54858 15.6826C4.2073 15.8239 3.89722 16.0311 3.63603 16.2923C3.37484 16.5535 3.16768 16.8636 3.02638 17.2048C2.88507 17.5461 2.8124 17.9119 2.8125 18.2812V16.875C2.8124 16.5056 2.88507 16.1399 3.02638 15.7986C3.16768 15.4573 3.37484 15.1472 3.63603 14.886C3.89722 14.6248 4.2073 14.4177 4.54858 14.2764C4.88985 14.1351 5.25563 14.0624 5.625 14.0625H7.73438Z" fill="#002046" />
        <path opacity="0.3" d="M87.1875 16.875V18.2812C87.1876 17.9119 87.1149 17.5461 86.9736 17.2048C86.8323 16.8636 86.6252 16.5535 86.364 16.2923C86.1028 16.0311 85.7927 15.8239 85.4514 15.6826C85.1101 15.5413 84.7444 15.4686 84.375 15.4688H82.2656V14.0625H84.375C84.7444 14.0624 85.1101 14.1351 85.4514 14.2764C85.7927 14.4177 86.1028 14.6248 86.364 14.886C86.6252 15.1472 86.8323 15.4573 86.9736 15.7986C87.1149 16.1399 87.1876 16.5056 87.1875 16.875Z" fill="#002046" />
        <path opacity="0.5" d="M2.8125 28.125H87.1875V29.5312H2.8125V28.125Z" fill="#F9EDD2" />
        <path opacity="0.5" d="M31.6406 54.1406H40.0781V55.5469H31.6406V54.1406Z" fill="white" />
        <path opacity="0.5" d="M31.6406 61.1719H40.0781V62.5781H31.6406V61.1719Z" fill="#9FA5AA" />
        <path opacity="0.5" d="M13.3594 54.1406H21.7969V55.5469H13.3594V54.1406Z" fill="white" />
        <path opacity="0.5" d="M13.3594 61.1719H21.7969V62.5781H13.3594V61.1719Z" fill="#9FA5AA" />
        <path opacity="0.5" d="M31.6406 35.8594H40.0781V37.2656H31.6406V35.8594Z" fill="white" />
        <path opacity="0.5" d="M31.6406 42.8906H40.0781V44.2969H31.6406V42.8906Z" fill="#9FA5AA" />
        <path opacity="0.5" d="M49.9219 35.8594H58.3594V37.2656H49.9219V35.8594Z" fill="white" />
        <path opacity="0.5" d="M68.2031 35.8594H76.6406V37.2656H68.2031V35.8594Z" fill="white" />
        <path opacity="0.5" d="M55.5469 42.8906C54.825 43.3234 54.1256 43.7928 53.4516 44.2969H49.9219V42.8906H55.5469Z" fill="#9FA5AA" />
        <path opacity="0.3" d="M87.1875 63.9844C87.1875 64.2164 87.1805 64.4555 87.1664 64.6875C86.9867 59.7782 84.9102 55.1299 81.3735 51.7204C77.8368 48.3108 73.1157 46.4056 68.2031 46.4056C63.2906 46.4056 58.5694 48.3108 55.0328 51.7204C51.4961 55.1299 49.4195 59.7782 49.2398 64.6875C49.2258 64.4555 49.2188 64.2164 49.2188 63.9844C49.2188 58.9494 51.2189 54.1207 54.7791 50.5604C58.3394 47.0001 63.1682 45 68.2031 45C73.2381 45 78.0668 47.0001 81.6271 50.5604C85.1874 54.1207 87.1875 58.9494 87.1875 63.9844Z" fill="#E8FBFC" />
        <path opacity="0.25" d="M87.1875 63.9844C87.1875 69.0193 85.1874 73.8481 81.6271 77.4084C78.0668 80.9686 73.2381 82.9688 68.2031 82.9688C63.1682 82.9688 58.3394 80.9686 54.7791 77.4084C51.2189 73.8481 49.2188 69.0193 49.2188 63.9844C49.2187 63.7523 49.2258 63.5133 49.2398 63.2812C49.4195 68.1905 51.4961 72.8388 55.0328 76.2484C58.5694 79.658 63.2906 81.5631 68.2031 81.5631C73.1157 81.5631 77.8368 79.658 81.3735 76.2484C84.9102 72.8388 86.9867 68.1905 87.1664 63.2812C87.1805 63.5133 87.1875 63.7523 87.1875 63.9844Z" fill="#0F423C" />
      </g>
      <defs>
        <clipPath id="clip0_2073_1225">
          <rect width="90" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
    , label: 'דוחות נוכחות לפי חודש ', link: '/AttendanceReportForAllUserByMonth'
  },
  {
    icon: <svg width="80" height="69" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2073_1225)">
        <path d="M83.2324 12.6562C83.0342 12.6562 82.7021 12.6561 82.2656 12.6558V14.7656C82.2656 17.1899 81.3026 19.5148 79.5884 21.229C77.8742 22.9432 75.5492 23.9062 73.125 23.9062C70.7008 23.9062 68.3758 22.9432 66.6616 21.229C64.9474 19.5148 63.9844 17.1899 63.9844 14.7656V12.6369C52.4359 12.6241 37.8079 12.607 26.0156 12.5932V14.7656C26.0156 17.1899 25.0526 19.5148 23.3384 21.229C21.6242 22.9432 19.2992 23.9062 16.875 23.9062C14.4508 23.9062 12.1258 22.9432 10.4116 21.229C8.6974 19.5148 7.73438 17.1899 7.73438 14.7656V12.5715C6.05115 12.5695 5.09766 12.5684 5.09766 12.5684C5.09766 12.5684 1.58203 12.6562 1.58203 16.084V26.8066H88.418V15.293C88.418 15.293 87.8906 12.6562 83.2324 12.6562Z" fill="#002046" />
        <path opacity="0.3" d="M26.0156 12.6562H63.9844V15.4688H26.0156V12.6562Z" fill="#002046" />
        <path d="M88.0664 26.8945V49.4789C85.0072 45.2842 80.6991 42.1649 75.7591 40.5678C70.819 38.9707 65.5003 38.9776 60.5644 40.5875C55.6285 42.1974 51.3286 45.3278 48.2802 49.5305C45.2319 53.7331 43.5914 58.7926 43.5938 63.9844C43.5912 65.6378 43.7585 67.287 44.093 68.9062C44.1844 69.3773 44.2969 69.8484 44.4164 70.3125C44.4797 70.5445 44.55 70.7836 44.6203 71.0156C44.6334 71.0751 44.6499 71.1338 44.6695 71.1914H1.40625V26.8945H88.0664Z" fill="#F1F7FF" />
        <path d="M41.4844 33.0469H30.2344C29.8617 33.048 29.5047 33.1965 29.2412 33.46C28.9777 33.7234 28.8292 34.0805 28.8281 34.4531V45.7031C28.8292 46.0757 28.9777 46.4328 29.2412 46.6963C29.5047 46.9598 29.8618 47.1083 30.2344 47.1094H41.4844C41.857 47.1083 42.214 46.9598 42.4775 46.6963C42.741 46.4328 42.8895 46.0757 42.8906 45.7031V34.4531C42.8895 34.0805 42.741 33.7234 42.4775 33.46C42.2141 33.1965 41.857 33.048 41.4844 33.0469ZM40.0781 44.2969H31.6406V35.8594H40.0781V44.2969Z" fill="#002046" />
        <path d="M31.6406 35.8594H40.0781V44.2969H31.6406V35.8594Z" fill="#E1EBF4" />
        <path d="M41.4844 51.3281H30.2344C29.8617 51.3292 29.5047 51.4777 29.2412 51.7412C28.9777 52.0047 28.8292 52.3617 28.8281 52.7344V63.9844C28.8292 64.357 28.9777 64.714 29.2412 64.9775C29.5047 65.241 29.8618 65.3895 30.2344 65.3906H41.4844C41.857 65.3895 42.214 65.241 42.4775 64.9775C42.741 64.714 42.8895 64.357 42.8906 63.9844V52.7344C42.8895 52.3617 42.741 52.0047 42.4775 51.7412C42.2141 51.4777 41.857 51.3292 41.4844 51.3281ZM40.0781 62.5781H31.6406V54.1406H40.0781V62.5781Z" fill="#002046" />
        <path d="M31.6406 54.1406H40.0781V62.5781H31.6406V54.1406Z" fill="#E1EBF4" />
        <path d="M23.2031 51.3281H11.9531C11.5805 51.3292 11.2234 51.4777 10.96 51.7412C10.6965 52.0047 10.548 52.3617 10.5469 52.7344V63.9844C10.548 64.357 10.6965 64.714 10.96 64.9775C11.2235 65.241 11.5805 65.3895 11.9531 65.3906H23.2031C23.5757 65.3895 23.9328 65.241 24.1963 64.9775C24.4598 64.714 24.6083 64.357 24.6094 63.9844V52.7344C24.6083 52.3617 24.4598 52.0047 24.1963 51.7412C23.9328 51.4777 23.5758 51.3292 23.2031 51.3281ZM21.7969 62.5781H13.3594V54.1406H21.7969V62.5781Z" fill="#002046" />
        <path d="M13.3594 54.1406H21.7969V62.5781H13.3594V54.1406Z" fill="#E1EBF4" />
        <path d="M26.0156 11.25H63.9844V14.0625H26.0156V11.25Z" fill="#002046" />
        <path d="M73.125 4.21875C71.4474 4.22127 69.8393 4.88879 68.6531 6.07499C67.4669 7.2612 66.7994 8.86932 66.7969 10.5469V14.7656C66.7969 16.4439 67.4636 18.0535 68.6503 19.2403C69.8371 20.427 71.4467 21.0938 73.125 21.0938C74.8033 21.0938 76.4129 20.427 77.5997 19.2403C78.7864 18.0535 79.4531 16.4439 79.4531 14.7656V10.5469C79.4506 8.86932 78.7831 7.2612 77.5969 6.07499C76.4107 4.88879 74.8026 4.22127 73.125 4.21875ZM76.6406 14.7656C76.6406 15.698 76.2702 16.5922 75.6109 17.2515C74.9516 17.9109 74.0574 18.2812 73.125 18.2812C72.1926 18.2812 71.2984 17.9109 70.6391 17.2515C69.9798 16.5922 69.6094 15.698 69.6094 14.7656V10.5469C69.6094 9.61447 69.9798 8.72026 70.6391 8.06095C71.2984 7.40165 72.1926 7.03125 73.125 7.03125C74.0574 7.03125 74.9516 7.40165 75.6109 8.06095C76.2702 8.72026 76.6406 9.61447 76.6406 10.5469V14.7656Z" fill="#002046" />
        <path d="M76.6406 10.5469V14.7656C76.6406 15.698 76.2702 16.5922 75.6109 17.2515C74.9516 17.9109 74.0574 18.2812 73.125 18.2812C72.1926 18.2812 71.2984 17.9109 70.6391 17.2515C69.9798 16.5922 69.6094 15.698 69.6094 14.7656V10.5469C69.6094 9.61447 69.9798 8.72026 70.6391 8.06095C71.2984 7.40165 72.1926 7.03125 73.125 7.03125C74.0574 7.03125 74.9516 7.40165 75.6109 8.06095C76.2702 8.72026 76.6406 9.61447 76.6406 10.5469Z" fill="#E1EBF4" />
        <path d="M16.875 4.21875C15.1974 4.22127 13.5893 4.88879 12.4031 6.07499C11.2169 7.2612 10.5494 8.86932 10.5469 10.5469V14.7656C10.5469 16.4439 11.2136 18.0535 12.4003 19.2403C13.5871 20.427 15.1967 21.0938 16.875 21.0938C18.5533 21.0938 20.1629 20.427 21.3497 19.2403C22.5364 18.0535 23.2031 16.4439 23.2031 14.7656V10.5469C23.2006 8.86932 22.5331 7.2612 21.3469 6.07499C20.1607 4.88879 18.5526 4.22127 16.875 4.21875ZM20.3906 14.7656C20.3906 15.698 20.0202 16.5922 19.3609 17.2515C18.7016 17.9109 17.8074 18.2812 16.875 18.2812C15.9426 18.2812 15.0484 17.9109 14.3891 17.2515C13.7298 16.5922 13.3594 15.698 13.3594 14.7656V10.5469C13.3594 9.61447 13.7298 8.72026 14.3891 8.06095C15.0484 7.40165 15.9426 7.03125 16.875 7.03125C17.8074 7.03125 18.7016 7.40165 19.3609 8.06095C20.0202 8.72026 20.3906 9.61447 20.3906 10.5469V14.7656Z" fill="#002046" />
        <path d="M20.3906 10.5469V14.7656C20.3906 15.698 20.0202 16.5922 19.3609 17.2515C18.7016 17.9109 17.8074 18.2812 16.875 18.2812C15.9426 18.2812 15.0484 17.9109 14.3891 17.2515C13.7298 16.5922 13.3594 15.698 13.3594 14.7656V10.5469C13.3594 9.61447 13.7298 8.72026 14.3891 8.06095C15.0484 7.40165 15.9426 7.03125 16.875 7.03125C17.8074 7.03125 18.7016 7.40165 19.3609 8.06095C20.0202 8.72026 20.3906 9.61447 20.3906 10.5469Z" fill="#E1EBF4" />
        <path d="M61.1719 40.4039V34.4531C61.1719 34.0802 61.0237 33.7225 60.76 33.4588C60.4963 33.1951 60.1386 33.0469 59.7656 33.0469H48.5156C48.1427 33.0469 47.785 33.1951 47.5213 33.4588C47.2576 33.7225 47.1094 34.0802 47.1094 34.4531V45.7031C47.1094 46.0761 47.2576 46.4338 47.5213 46.6975C47.785 46.9612 48.1427 47.1094 48.5156 47.1094H50.3157C51.2804 46.0845 52.3305 45.1437 53.4548 44.2969H49.9219V35.8594H58.3594V41.436C59.2749 41.0347 60.2141 40.6901 61.1719 40.4039Z" fill="#002046" />
        <path d="M79.4531 42.1083V34.4531C79.4531 34.0802 79.3049 33.7225 79.0412 33.4588C78.7775 33.1951 78.4198 33.0469 78.0469 33.0469H66.7969C66.4239 33.0469 66.0662 33.1951 65.8025 33.4588C65.5388 33.7225 65.3906 34.0802 65.3906 34.4531V39.5405C66.3242 39.432 67.2632 39.3767 68.2031 39.375V35.8594H76.6406V40.8655C77.6033 41.2204 78.5426 41.6354 79.4531 42.1083Z" fill="#002046" />
        <path d="M84.375 11.25H82.2656V14.0625H84.375C85.1207 14.0633 85.8356 14.3599 86.3629 14.8871C86.8901 15.4144 87.1867 16.1293 87.1875 16.875V25.3125H2.8125V16.875C2.81329 16.1293 3.10987 15.4144 3.63714 14.8871C4.16441 14.3599 4.87932 14.0633 5.625 14.0625H7.73438V11.25H5.625C4.13368 11.2517 2.70393 11.8449 1.64941 12.8994C0.594887 13.9539 0.00170649 15.3837 0 16.875L0 67.5C0.00170649 68.9913 0.594887 70.4211 1.64941 71.4756C2.70393 72.5301 4.13368 73.1233 5.625 73.125H45.3612C44.9916 72.2074 44.6781 71.2682 44.4225 70.3125H5.625C4.87932 70.3117 4.16441 70.0151 3.63714 69.4879C3.10987 68.9606 2.81329 68.2457 2.8125 67.5V28.125H87.1875V48.3418C88.2683 49.6539 89.2108 51.0742 90 52.5799V16.875C89.9983 15.3837 89.4051 13.9539 88.3506 12.8994C87.2961 11.8449 85.8663 11.2517 84.375 11.25Z" fill="#002046" />
        <path d="M68.2031 42.1875C63.8921 42.1875 59.6779 43.4659 56.0934 45.8609C52.509 48.256 49.7152 51.6602 48.0654 55.6431C46.4157 59.6259 45.984 64.0086 46.8251 68.2367C47.6661 72.4649 49.7421 76.3487 52.7904 79.3971C55.8388 82.4454 59.7226 84.5214 63.9508 85.3624C68.179 86.2035 72.5616 85.7718 76.5444 84.1221C80.5273 82.4723 83.9315 79.6786 86.3266 76.0941C88.7216 72.5096 90 68.2954 90 63.9844C89.9927 58.2057 87.6938 52.6659 83.6077 48.5798C79.5216 44.4937 73.9818 42.1949 68.2031 42.1875ZM68.2031 82.9688C64.4484 82.9688 60.7779 81.8553 57.656 79.7693C54.534 77.6833 52.1007 74.7183 50.6639 71.2494C49.227 67.7804 48.851 63.9633 49.5835 60.2807C50.3161 56.5981 52.1241 53.2154 54.7792 50.5604C57.4342 47.9054 60.8169 46.0973 64.4995 45.3648C68.1821 44.6323 71.9992 45.0082 75.4681 46.4451C78.9371 47.882 81.902 50.3153 83.9881 53.4372C86.0741 56.5592 87.1875 60.2296 87.1875 63.9844C87.1823 69.0178 85.1805 73.8435 81.6214 77.4026C78.0623 80.9618 73.2365 82.9636 68.2031 82.9688Z" fill="#002046" />
        <path d="M87.1875 63.9844C87.1875 67.7391 86.0741 71.4096 83.9881 74.5315C81.902 77.6535 78.9371 80.0868 75.4681 81.5237C71.9992 82.9605 68.1821 83.3365 64.4995 82.604C60.8169 81.8715 57.4342 80.0634 54.7792 77.4084C52.1241 74.7533 50.3161 71.3707 49.5835 67.688C48.851 64.0054 49.227 60.1883 50.6639 56.7194C52.1007 53.2504 54.534 50.2855 57.656 48.1994C60.7779 46.1134 64.4484 45 68.2031 45C73.2365 45.0052 78.0623 47.007 81.6214 50.5661C85.1805 54.1252 87.1823 58.951 87.1875 63.9844Z" fill="#00CF7F" />
        <path d="M77.7544 55.2568L63.3218 69.7275L57.2457 63.6356C56.982 63.3726 56.6248 63.225 56.2525 63.225C55.8801 63.225 55.5229 63.3727 55.2593 63.6357C54.9957 63.8987 54.8471 64.2555 54.8462 64.6279C54.8453 65.0003 54.9921 65.3578 55.2544 65.6221L62.3262 72.712C62.592 72.9727 62.9495 73.1187 63.3218 73.1187C63.6941 73.1187 64.0516 72.9727 64.3174 72.712L79.7456 57.2433C80.008 56.979 80.1548 56.6214 80.1539 56.2491C80.153 55.8767 80.0044 55.5199 79.7408 55.2569C79.4771 54.9939 79.12 54.8462 78.7476 54.8462C78.3752 54.8462 78.0181 54.9938 77.7544 55.2568Z" fill="#002046" />
        <path d="M58.3594 35.8594V41.4352C57.3924 41.8611 56.4531 42.3472 55.5469 42.8906C54.825 43.3234 54.1256 43.7928 53.4516 44.2969H49.9219V35.8594H58.3594Z" fill="#E1EBF4" />
        <path d="M76.6406 35.8594H68.2031V39.375C71.0814 39.3723 73.9375 39.8768 76.6406 40.8655V35.8594Z" fill="#E1EBF4" />
        <path opacity="0.5" d="M76.6406 10.5469V11.9531C76.6406 11.0207 76.2702 10.1265 75.6109 9.4672C74.9516 8.8079 74.0574 8.4375 73.125 8.4375C72.1926 8.4375 71.2984 8.8079 70.6391 9.4672C69.9798 10.1265 69.6094 11.0207 69.6094 11.9531V10.5469C69.6094 9.61447 69.9798 8.72026 70.6391 8.06095C71.2984 7.40165 72.1926 7.03125 73.125 7.03125C74.0574 7.03125 74.9516 7.40165 75.6109 8.06095C76.2702 8.72026 76.6406 9.61447 76.6406 10.5469Z" fill="white" />
        <path opacity="0.5" d="M20.3906 10.5469V11.9531C20.3906 11.0207 20.0202 10.1265 19.3609 9.4672C18.7016 8.8079 17.8074 8.4375 16.875 8.4375C15.9426 8.4375 15.0484 8.8079 14.3891 9.4672C13.7298 10.1265 13.3594 11.0207 13.3594 11.9531V10.5469C13.3594 9.61447 13.7298 8.72026 14.3891 8.06095C15.0484 7.40165 15.9426 7.03125 16.875 7.03125C17.8074 7.03125 18.7016 7.40165 19.3609 8.06095C20.0202 8.72026 20.3906 9.61447 20.3906 10.5469Z" fill="white" />
        <path opacity="0.45" d="M44.4164 70.3125H5.625C5.25563 70.3126 4.88986 70.2399 4.54858 70.0986C4.20731 69.9573 3.89722 69.7501 3.63604 69.489C3.37485 69.2278 3.16769 68.9177 3.02638 68.5764C2.88508 68.2351 2.8124 67.8694 2.8125 67.5V66.0938C2.8124 66.4631 2.88508 66.8289 3.02638 67.1702C3.16769 67.5114 3.37485 67.8215 3.63604 68.0827C3.89722 68.3439 4.20731 68.5511 4.54858 68.6924C4.88986 68.8337 5.25563 68.9064 5.625 68.9062H44.093C44.1844 69.3773 44.2969 69.8484 44.4164 70.3125Z" fill="#00BCFF" />
        <path opacity="0.5" d="M2.8125 23.9062H87.1875V25.3125H2.8125V23.9062Z" fill="white" />
        <path opacity="0.3" d="M7.73438 14.0625V15.4688H5.625C5.25563 15.4686 4.88985 15.5413 4.54858 15.6826C4.2073 15.8239 3.89722 16.0311 3.63603 16.2923C3.37484 16.5535 3.16768 16.8636 3.02638 17.2048C2.88507 17.5461 2.8124 17.9119 2.8125 18.2812V16.875C2.8124 16.5056 2.88507 16.1399 3.02638 15.7986C3.16768 15.4573 3.37484 15.1472 3.63603 14.886C3.89722 14.6248 4.2073 14.4177 4.54858 14.2764C4.88985 14.1351 5.25563 14.0624 5.625 14.0625H7.73438Z" fill="#002046" />
        <path opacity="0.3" d="M87.1875 16.875V18.2812C87.1876 17.9119 87.1149 17.5461 86.9736 17.2048C86.8323 16.8636 86.6252 16.5535 86.364 16.2923C86.1028 16.0311 85.7927 15.8239 85.4514 15.6826C85.1101 15.5413 84.7444 15.4686 84.375 15.4688H82.2656V14.0625H84.375C84.7444 14.0624 85.1101 14.1351 85.4514 14.2764C85.7927 14.4177 86.1028 14.6248 86.364 14.886C86.6252 15.1472 86.8323 15.4573 86.9736 15.7986C87.1149 16.1399 87.1876 16.5056 87.1875 16.875Z" fill="#002046" />
        <path opacity="0.5" d="M2.8125 28.125H87.1875V29.5312H2.8125V28.125Z" fill="#F9EDD2" />
        <path opacity="0.5" d="M31.6406 54.1406H40.0781V55.5469H31.6406V54.1406Z" fill="white" />
        <path opacity="0.5" d="M31.6406 61.1719H40.0781V62.5781H31.6406V61.1719Z" fill="#9FA5AA" />
        <path opacity="0.5" d="M13.3594 54.1406H21.7969V55.5469H13.3594V54.1406Z" fill="white" />
        <path opacity="0.5" d="M13.3594 61.1719H21.7969V62.5781H13.3594V61.1719Z" fill="#9FA5AA" />
        <path opacity="0.5" d="M31.6406 35.8594H40.0781V37.2656H31.6406V35.8594Z" fill="white" />
        <path opacity="0.5" d="M31.6406 42.8906H40.0781V44.2969H31.6406V42.8906Z" fill="#9FA5AA" />
        <path opacity="0.5" d="M49.9219 35.8594H58.3594V37.2656H49.9219V35.8594Z" fill="white" />
        <path opacity="0.5" d="M68.2031 35.8594H76.6406V37.2656H68.2031V35.8594Z" fill="white" />
        <path opacity="0.5" d="M55.5469 42.8906C54.825 43.3234 54.1256 43.7928 53.4516 44.2969H49.9219V42.8906H55.5469Z" fill="#9FA5AA" />
        <path opacity="0.3" d="M87.1875 63.9844C87.1875 64.2164 87.1805 64.4555 87.1664 64.6875C86.9867 59.7782 84.9102 55.1299 81.3735 51.7204C77.8368 48.3108 73.1157 46.4056 68.2031 46.4056C63.2906 46.4056 58.5694 48.3108 55.0328 51.7204C51.4961 55.1299 49.4195 59.7782 49.2398 64.6875C49.2258 64.4555 49.2188 64.2164 49.2188 63.9844C49.2188 58.9494 51.2189 54.1207 54.7791 50.5604C58.3394 47.0001 63.1682 45 68.2031 45C73.2381 45 78.0668 47.0001 81.6271 50.5604C85.1874 54.1207 87.1875 58.9494 87.1875 63.9844Z" fill="#E8FBFC" />
        <path opacity="0.25" d="M87.1875 63.9844C87.1875 69.0193 85.1874 73.8481 81.6271 77.4084C78.0668 80.9686 73.2381 82.9688 68.2031 82.9688C63.1682 82.9688 58.3394 80.9686 54.7791 77.4084C51.2189 73.8481 49.2188 69.0193 49.2188 63.9844C49.2187 63.7523 49.2258 63.5133 49.2398 63.2812C49.4195 68.1905 51.4961 72.8388 55.0328 76.2484C58.5694 79.658 63.2906 81.5631 68.2031 81.5631C73.1157 81.5631 77.8368 79.658 81.3735 76.2484C84.9102 72.8388 86.9867 68.1905 87.1664 63.2812C87.1805 63.5133 87.1875 63.7523 87.1875 63.9844Z" fill="#0F423C" />
      </g>
      <defs>
        <clipPath id="clip0_2073_1225">
          <rect width="90" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
, label: 'דוח נוכחות לחודש הנוכחי', link: '/AttendanceReportAllUsers'
// , label: 'דוח נוחכות לחודש הנוכחי ', link: `/AttendanceReport/${userId}`
  }

];
    return(
        <>  
         <div>
              <div className='div'>
              </div>
              <Box sx={{ padding: 3, backgroundColor: '#f4f7fc', marginTop: 2, marginRight: "6%", marginLeft: "6%" }}>
                  <Typography
                    sx={{
                      marginBottom: 0,
                      textAlign: 'right',
                      fontSize: '28px',
                      fontWeight: 700,
                      fontFamily: 'CustomFont',
                      lineHeight: '48px',
                      '@media (max-width: 600px)': {
                        marginBottom: "30px",
                        fontSize: '22px !important',
                      },
                    }}
                  ></Typography>
                  <Grid container spacing={2} marginTop={"5%"} marginBottom={"5%"}>
                    {actions.map((action, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Link to={action.link} style={{}}>
                          <Card className='card' sx={{ borderRadius: '15px', width: '100%' }}>
                            <IconButton>
                              {action.icon}
                            </IconButton>
                            <CardContent>
                              <Typography variant="body1" sx={{ display: 'inline-block', marginLeft: '8px', fontWeight: 700, fontSize: "22px" }} className='label'>
                                <GoArrowDownLeft className='arrow' />
                                {action.label}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
            </div>     
        </>
    )
}
