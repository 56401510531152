import React from "react"

export const NotFound = () => {
    return (
        <>
        <h1>404</h1>
        <h2>Not Found</h2>
        <h3>Error!!!</h3>
        </>
    )
}