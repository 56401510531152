import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography, IconButton, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import './userScreen.css';
import { GoArrowDownLeft } from "react-icons/go";
import { Project } from '../../model/project.model';
import { GetAllProjectPerUser } from '../../api/user.api';
import MoreStatus from '../project/moreStatus';
import MyChatBot2 from '../project/projects/projectMain/chatBot';


const QuickActions = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [endDate, setEndDate] = useState<string>("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getProject();
    }, []);

    const getProject = async () => {
        try {
            const userId = sessionStorage.getItem('userId');
            if (userId) {
                const projectPerCustomer = await GetAllProjectPerUser(userId);
                console.log("Projects:", projectPerCustomer);
                if (projectPerCustomer.length > 0) {
                    setProjects(projectPerCustomer)
                    setSelectedProject(projectPerCustomer[0]);
                    const dateObject = new Date(projectPerCustomer[0].endDate);
                    setEndDate(dateObject.toISOString().split('T')[0]);
                }
            }
        } catch (error) {
            console.error("Failed to fetch projects:", error);
        }
    };
    const handleChangeProject = (event: SelectChangeEvent<string>) => {
      const projectId = event.target.value as string;
      const project = projects.find(proj => proj.projectId === projectId) || null;
      setSelectedProject(project);
      if (project) {
          const dateObject = new Date(project.endDate);
          setEndDate(dateObject.toISOString().split('T')[0]);
      }
  };

    if (!selectedProject) {
        return <p>Loading...</p>;
    }

    const actions = [
        { icon: <svg width="35" height="35" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1439_1088)">
          <path d="M14.399 14.8136H11.7482L18.9748 35.2415L23.0205 23.6804L19.8838 14.8136H17.0871V12.8671H29.472V14.8136H26.5717L33.7982 35.2415L36.3959 27.8181C39.8034 18.3074 33.603 15.3448 33.603 13.2223C33.603 11.0997 35.3236 9.37914 37.4461 9.37914C37.5609 9.37914 37.6724 9.38536 37.7817 9.39554C34.1854 6.00061 29.3361 3.9187 24.0002 3.9187C17.0279 3.9187 10.8864 7.47264 7.28613 12.8671H14.3987V14.8136H14.399Z" fill="#00769D"/>
          <path d="M3.91895 24.0001C3.91895 31.6883 8.24016 38.3662 14.5856 41.7406L5.52329 16.1235C4.49097 18.542 3.91895 21.2042 3.91895 24.0001Z" fill="#00769D"/>
          <path d="M41.4763 14.1045C41.7635 15.7958 41.6766 17.6967 41.2358 19.605H41.3157L41.012 20.4727C40.8321 21.0992 40.6055 21.74 40.3482 22.3697L33.4385 41.7275C39.7706 38.349 44.0808 31.6782 44.0808 23.9998C44.0808 20.4018 43.1336 17.0253 41.4763 14.1045Z" fill="#00769D"/>
          <path d="M17.8066 43.1064C19.7575 43.7384 21.8385 44.0811 23.9999 44.0811C26.0616 44.0811 28.0504 43.77 29.9227 43.1928L24.0487 26.5884L17.8066 43.1064Z" fill="#00769D"/>
          <path d="M40.9705 7.0294C36.4375 2.49642 30.4105 0 23.9999 0C17.5893 0 11.5624 2.49642 7.0294 7.0294C2.49642 11.5624 0 17.5893 0 24C0 30.4106 2.49642 36.4375 7.0294 40.9705C11.5624 45.5035 17.5894 47.9999 24 47.9999C30.4106 47.9999 36.4375 45.5035 40.9706 40.9705C45.5036 36.4375 48 30.4105 48 24C47.9999 17.5893 45.5035 11.5624 40.9705 7.0294ZM23.9999 46.3069C11.6998 46.3069 1.69302 36.3001 1.69302 24C1.69302 11.6999 11.6998 1.69302 23.9999 1.69302C36.2999 1.69302 46.3068 11.6999 46.3068 24C46.3068 36.3001 36.3001 46.3069 23.9999 46.3069Z" fill="#00769D"/>
          </g>
          <defs>
          <clipPath id="clip0_1439_1088">
          <rect width="48" height="48" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          , label: 'לינק לאתר',link:selectedProject.urlWordpress},
      
        { icon: <svg width="35" height="35" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1439_1131)">
          <path d="M2.8621 30.9279L4.53277 33.8255C4.87989 34.4355 5.3796 34.9152 5.9666 35.2645L11.9593 31.0156L11.9702 24.9156L5.9899 22.2212L6.07675e-06 24.8941C-0.00118142 25.5688 0.171674 26.2438 0.518869 26.8538L2.8621 30.9279Z" fill="#0066DD"/>
          <path d="M19.0217 12.8997L19.3344 4.9535L13.0552 2.5293C12.4669 2.87649 11.9655 3.35439 11.6161 3.96313L0.52584 23.0957C0.176566 23.7045 0.0011875 24.3789 0 25.0535L11.9702 25.0749L19.0217 12.8997Z" fill="#00AD3C"/>
          <path d="M19.0214 12.8997L24.7735 9.80525L25.0249 2.55074C24.4379 2.20147 23.7635 2.02616 23.0671 2.0249L15.0144 2.0105C14.318 2.00924 13.6429 2.20392 13.0547 2.52937L19.0214 12.8997Z" fill="#00831E"/>
          <path d="M26.0299 24.9406L11.9704 24.9155L5.9668 35.2644C6.55379 35.6137 7.22822 35.789 7.92461 35.7903L30.0368 35.8298C30.7333 35.831 31.4083 35.6364 31.9966 35.3109L32.0199 28.3596L26.0299 24.9406Z" fill="#0084FF"/>
          <path d="M31.9965 35.311C32.5848 34.9638 33.0862 34.4859 33.4355 33.8771L34.1341 32.6814L37.4743 26.9199C37.8236 26.3111 37.999 25.6368 38.0002 24.962L31.0814 21.3215L26.0518 24.9407L31.9965 35.311Z" fill="#FF4131"/>
          <path d="M31.97 13.5759L26.459 3.98981C26.1119 3.37973 25.6122 2.90005 25.0251 2.55078L19.0215 12.8998L26.0294 25.1002L37.9779 25.1216C37.9791 24.4469 37.8062 23.7719 37.4591 23.1618L31.97 13.5759Z" fill="#FFBA00"/>
          </g>
          <defs>
          <clipPath id="clip0_1439_1131">
          <rect width="38" height="38" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          , label: 'לינק לתקיית הדרייב' ,link:selectedProject.urlDrive},

          {icon: <svg width="35" height="35" viewBox="0 0 39 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M38.6826 11.1508L27.8493 0.317383C27.6461 0.114258 27.3711 0 27.0833 0H4.33327C1.94452 0 0 1.9434 0 4.33337V47.6667C0 50.0566 1.94452 52 4.33337 52H34.6667C37.0555 52 39 50.0566 39 47.6666V11.9166C39 11.6289 38.8857 11.3539 38.6826 11.1508ZM28.1666 3.6986L35.3014 10.8334H30.3333C29.1378 10.8334 28.1666 9.86111 28.1666 8.66673V3.6986ZM36.8334 47.6666C36.8334 48.861 35.8621 49.8333 34.6667 49.8333H4.33337C3.13788 49.8333 2.16673 48.861 2.16673 47.6666V4.33337C2.16673 3.13899 3.13798 2.16673 4.33337 2.16673H26V8.66673C26 11.0566 27.9445 13 30.3334 13H36.8334V47.6666Z" fill="#002046"/>
              <path d="M31.4166 30.3335H7.58337C6.98456 30.3335 6.5 30.8181 6.5 31.4169C6.5 32.0157 6.98456 32.5002 7.58337 32.5002H31.4167C32.0155 32.5002 32.5001 32.0157 32.5001 31.4169C32.5 30.8181 32.0154 30.3335 31.4166 30.3335Z" fill="#002046"/>
              <path d="M31.4166 23.8335H7.58337C6.98456 23.8335 6.5 24.318 6.5 24.9169C6.5 25.5157 6.98456 26.0002 7.58337 26.0002H31.4167C32.0155 26.0002 32.5001 25.5157 32.5001 24.9169C32.5 24.318 32.0154 23.8335 31.4166 23.8335Z" fill="#002046"/>
              <path d="M31.4166 36.8335H7.58337C6.98456 36.8335 6.5 37.3181 6.5 37.9169C6.5 38.5157 6.98456 39.0002 7.58337 39.0002H31.4167C32.0155 39.0002 32.5001 38.5157 32.5001 37.9169C32.5 37.3181 32.0154 36.8335 31.4166 36.8335Z" fill="#002046"/>
              <path d="M20.5834 43.3335H7.58337C6.98456 43.3335 6.5 43.8181 6.5 44.4169C6.5 45.0157 6.98456 45.5002 7.58337 45.5002H20.5834C21.1822 45.5002 21.6667 45.0157 21.6667 44.4169C21.6666 43.8181 21.1822 43.3335 20.5834 43.3335Z" fill="#002046"/>
              <path d="M17.333 18.4166C17.333 19.0154 17.8176 19.5 18.4164 19.5H31.4164C32.0152 19.5 32.4997 19.0154 32.4997 18.4166C32.4997 17.8178 32.0152 17.3333 31.4164 17.3333H18.4164C17.8176 17.3334 17.333 17.8179 17.333 18.4166Z" fill="#002046"/>
              <path d="M9.75 17.3332H7.58337C6.98456 17.3332 6.5 17.8178 6.5 18.4166C6.5 19.0154 6.98456 19.4999 7.58337 19.4999H8.66673C8.66673 20.0988 9.15129 20.5833 9.7501 20.5833C10.3489 20.5833 10.8335 20.0988 10.8335 19.4999V19.3004C12.0916 18.8516 13 17.6603 13 16.2498C13 14.4577 11.5422 12.9998 9.75 12.9998C9.15332 12.9998 8.66663 12.5143 8.66663 11.9165C8.66663 11.3188 9.15332 10.8331 9.75 10.8331H11.9166C12.5154 10.8331 13 10.3486 13 9.74974C13 9.15093 12.5154 8.66637 11.9166 8.66637H10.8333C10.8333 8.06756 10.3487 7.58301 9.7499 7.58301C9.15109 7.58301 8.66653 8.06756 8.66653 8.66637V8.86594C7.40838 9.31475 6.5 10.506 6.5 11.9165C6.5 13.7086 7.95783 15.1665 9.75 15.1665C10.3467 15.1665 10.8334 15.652 10.8334 16.2498C10.8334 16.8476 10.3467 17.3332 9.75 17.3332Z" fill="#002046"/>
              </svg>
              ,label:'חשבונית להורדה',link:"/documents"},
      
          {icon: <svg width="35" height="35" viewBox="0 0 72 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M57.6613 26.8976C65.082 26.8976 71.1192 20.8684 71.1192 13.4579C71.1192 6.03724 65.082 0 57.6613 0C50.2406 0 44.2031 6.03724 44.2031 13.4579C44.2031 20.8684 50.2404 26.8976 57.6613 26.8976ZM57.6613 3.01273C63.4205 3.01273 68.1065 7.69872 68.1065 13.4579C68.1065 19.2075 63.4205 23.8848 57.6613 23.8848C51.902 23.8848 47.2161 19.2075 47.2161 13.4579C47.2159 7.69872 51.902 3.01273 57.6613 3.01273Z" fill="#002046"/>
              <path d="M55.0248 17.3686C55.3073 17.651 55.6904 17.8098 56.0898 17.8098C56.4891 17.8098 56.8723 17.651 57.1547 17.3686L62.864 11.6593C63.4524 11.0709 63.4524 10.1176 62.864 9.52921C62.2756 8.94078 61.3223 8.94078 60.7339 9.52921L56.0898 14.1726L54.59 12.6727C54.0015 12.0843 53.0483 12.0843 52.4599 12.6727C51.8714 13.2604 51.8714 14.2144 52.4599 14.8028L55.0248 17.3686Z" fill="#00CF7F"/>
              <path d="M1.50637 10.0332H37.0677C37.8996 10.0332 38.574 9.35877 38.574 8.52687C38.574 7.69498 37.8996 7.02051 37.0677 7.02051H1.50637C0.674469 7.02051 0 7.69498 0 8.52687C0 9.35877 0.674662 10.0332 1.50637 10.0332Z" fill="#002046"/>
              <path d="M13.8765 19.877H37.0265C37.8584 19.877 38.5328 19.2025 38.5328 18.3706C38.5328 17.5387 37.8584 16.8643 37.0265 16.8643H13.8765C13.0446 16.8643 12.3701 17.5387 12.3701 18.3706C12.3701 19.2023 13.0448 19.877 13.8765 19.877Z" fill="#002046"/>
              <path d="M57.6611 61.6525C65.0818 61.6525 71.119 55.6234 71.119 48.2121C71.119 40.7914 65.0818 34.7542 57.6611 34.7542C50.2404 34.7542 44.2031 40.7914 44.2031 48.2121C44.2029 55.6234 50.2402 61.6525 57.6611 61.6525ZM57.6611 37.7671C63.4203 37.7671 68.1063 42.4531 68.1063 48.2123C68.1063 53.9619 63.4203 58.6399 57.6611 58.6399C51.9018 58.6399 47.2159 53.9621 47.2159 48.2123C47.2157 42.4531 51.9018 37.7671 57.6611 37.7671Z" fill="#002046"/>
              <path d="M55.0248 52.1232C55.319 52.4174 55.7045 52.5646 56.0898 52.5646C56.4752 52.5646 56.8605 52.4174 57.1547 52.1232L62.864 46.4139C63.4524 45.8255 63.4524 44.8723 62.864 44.2838C62.2756 43.6954 61.3223 43.6954 60.7339 44.2838L56.0898 48.928L54.59 47.4282C54.0015 46.8397 53.0483 46.8397 52.4599 47.4282C51.8714 48.0166 51.8714 48.9698 52.4599 49.5583L55.0248 52.1232Z" fill="#00CF7F"/>
              <path d="M1.50637 44.7876H37.0677C37.8996 44.7876 38.574 44.1132 38.574 43.2813C38.574 42.4494 37.8996 41.7749 37.0677 41.7749H1.50637C0.674469 41.7749 0 42.4494 0 43.2813C0 44.1132 0.674662 44.7876 1.50637 44.7876Z" fill="#002046"/>
              <path d="M13.8765 54.6319H37.0265C37.8584 54.6319 38.5328 53.9574 38.5328 53.1255C38.5328 52.2936 37.8584 51.6191 37.0265 51.6191H13.8765C13.0446 51.6191 12.3701 52.2936 12.3701 53.1255C12.3701 53.9574 13.0448 54.6319 13.8765 54.6319Z" fill="#002046"/>
              </svg>,label:'סטטוס פרויקט',link:"/projectStatus"},
      
        { icon: <svg width="35" height="35" viewBox="0 0 44 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.9995 28.8303C20.7694 28.8303 19.7383 29.8614 19.7383 31.0915C19.7383 32.3216 20.7694 33.3528 21.9995 33.3528C23.1844 33.3528 24.2607 32.3216 24.2064 31.1458C24.2607 29.8524 23.2386 28.8303 21.9995 28.8303Z" fill="#FF4131"/>
          <path d="M42.9293 36.8442C44.3493 34.393 44.3584 31.4715 42.9474 29.0294L28.7831 4.49971C27.3811 2.03046 24.8486 0.565186 22.0085 0.565186C19.1684 0.565186 16.6358 2.0395 15.2339 4.49066L1.05151 29.0475C-0.359491 31.5168 -0.350446 34.4563 1.07864 36.9075C2.48965 39.3315 5.01317 40.7878 7.83517 40.7878H36.1275C38.9586 40.7878 41.5002 39.3134 42.9293 36.8442ZM39.854 35.0714C39.0671 36.4281 37.6742 37.2331 36.1185 37.2331H7.82612C6.28849 37.2331 4.90463 36.4462 4.13581 35.1166C3.35795 33.7689 3.34891 32.1589 4.12677 30.8022L18.3091 6.25441C19.0779 4.90673 20.4528 4.11078 22.0085 4.11078C23.5552 4.11078 24.939 4.91577 25.7078 6.26346L39.8812 30.8113C40.6409 32.1318 40.6319 33.7237 39.854 35.0714Z" fill="#002046"/>
          <path d="M21.438 12.9567C20.3617 13.2642 19.6924 14.241 19.6924 15.4259C19.7467 16.1405 19.7919 16.864 19.8461 17.5786C19.9999 20.3011 20.1537 22.9693 20.3074 25.6918C20.3617 26.6144 21.0762 27.2837 21.9988 27.2837C22.9214 27.2837 23.645 26.5692 23.6902 25.6376C23.6902 25.0768 23.6902 24.5612 23.7445 23.9914C23.844 22.2457 23.9525 20.5001 24.052 18.7544C24.1063 17.6238 24.2058 16.4932 24.26 15.3626C24.26 14.9556 24.2058 14.5938 24.052 14.232C23.5907 13.219 22.5144 12.7034 21.438 12.9567Z" fill="#FF4131"/>
          </svg>
          , label: 'דיווח על תקלה באתר' ,link:"/reportIssue"},
      
      
        { icon: <svg width="35" height="35" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M35.0522 24.647C35.0522 23.7773 34.3469 23.072 33.4771 23.072H16.1521C15.2824 23.072 14.5771 23.7773 14.5771 24.647C14.5771 25.5168 15.2824 26.222 16.1521 26.222H33.4771C34.3469 26.222 35.0522 25.5168 35.0522 24.647Z" fill="#002046"/>
          <path d="M16.1487 31.0258C15.279 31.0258 14.5737 31.731 14.5737 32.6008C14.5737 33.4705 15.279 34.1758 16.1487 34.1758H25.3345C26.2042 34.1758 26.9095 33.4705 26.9095 32.6008C26.9095 31.731 26.2042 31.0258 25.3345 31.0258H16.1487Z" fill="#002046"/>
          <path d="M45.4298 24.7345C44.5601 24.7345 43.8548 25.4397 43.8548 26.3095V39.0477C43.8548 41.4312 41.9158 43.3702 39.5323 43.3702H28.9973C28.6561 43.3702 28.3236 43.4805 28.0506 43.687L18.9541 50.5277C18.7231 50.701 18.5061 50.6292 18.3976 50.5697C18.2873 50.5137 18.1053 50.3755 18.1123 50.0937L18.2873 45.0012C18.3013 44.5742 18.1421 44.1595 17.8463 43.8532C17.5506 43.5452 17.1411 43.372 16.7141 43.372H9.3973C7.0138 43.372 5.0748 41.4347 5.0748 39.0495V18.2245C5.0748 15.8322 7.0138 13.8862 9.3973 13.8862H28.8048C29.6746 13.8862 30.3798 13.181 30.3798 12.3112C30.3798 11.4415 29.6746 10.7362 28.8048 10.7362H9.3973C5.2778 10.7362 1.9248 14.0962 1.9248 18.2262V39.0495C1.9248 43.169 5.2778 46.5237 9.3973 46.5237H15.0831L14.9641 49.9922C14.9221 51.4167 15.6798 52.7117 16.9451 53.368C17.4858 53.6497 18.0686 53.788 18.6461 53.788C19.4213 53.788 20.1896 53.5377 20.8441 53.0495L29.5223 46.5237H39.5306C43.6501 46.5237 47.0031 43.1707 47.0031 39.0495V26.3112C47.0048 25.4397 46.2996 24.7345 45.4298 24.7345Z" fill="#002046"/>
          <path d="M53.9435 9.73174C53.6583 8.85674 52.9565 8.20224 52.0658 7.98174L48.0408 6.97899L45.8428 3.46149C44.868 1.89699 42.3235 1.90049 41.3505 3.46149L39.1525 6.97899L35.1275 7.98174C34.2368 8.20224 33.5333 8.85674 33.2498 9.73174C32.9663 10.6067 33.1483 11.55 33.7398 12.2552L36.4068 15.4297L36.1163 19.5667C36.0515 20.4837 36.4575 21.3535 37.2013 21.8942C37.6633 22.2302 38.2058 22.4035 38.7553 22.4035C39.0895 22.4035 39.4273 22.3387 39.751 22.2075L43.5975 20.6535L47.4423 22.2075C48.2945 22.554 49.2465 22.435 49.992 21.896C50.7358 21.3552 51.1418 20.4855 51.0788 19.5667L50.7883 15.4297L53.4553 12.2535C54.045 11.55 54.2288 10.6067 53.9435 9.73174ZM47.9655 13.8915C47.703 14.2047 47.5718 14.6072 47.6015 15.0167L47.8815 18.9892L44.189 17.4965C44 17.4195 43.7988 17.381 43.5993 17.381C43.3998 17.381 43.1985 17.4195 43.0095 17.4965L39.3188 18.9892L39.597 15.0167C39.625 14.609 39.4938 14.2047 39.233 13.8915L36.6728 10.843L40.5368 9.87874C40.9323 9.77899 41.2753 9.53224 41.4905 9.18574L43.601 5.80999L45.7115 9.18574C45.9285 9.53224 46.2698 9.78074 46.6653 9.87874L50.5293 10.843L47.9655 13.8915Z" fill="#002046"/>
          </svg>
          , label: '😊  נשמח לפידבק',link:"/feedback" },
      
          { icon: <svg width="35" height="35" viewBox="0 0 58 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M51.9151 0H6.55339C3.19799 0 0.468262 2.72985 0.468262 6.08513V30.9787V34.8511C0.468262 38.2064 3.19799 40.9362 6.55339 40.9362H21.2464L19.4762 49.7873H13.7449C13.1338 49.7873 12.6385 50.2826 12.6385 50.8936C12.6385 51.5047 13.1338 52 13.7449 52H44.7236C45.3346 52 45.8299 51.5047 45.8299 50.8936C45.8299 50.2826 45.3346 49.7873 44.7236 49.7873H38.9923L37.2221 40.9362H51.9151C55.2704 40.9362 58.0002 38.2065 58.0002 34.8511V30.9787V6.08513C58.0002 2.72985 55.2705 0 51.9151 0ZM21.7327 49.7873L23.5029 40.9362H34.9655L36.7357 49.7873H21.7327ZM55.7875 34.8511C55.7875 36.9863 54.0504 38.7235 51.9151 38.7235H6.55339C4.41819 38.7235 2.68099 36.9864 2.68099 34.8511V32.0852H55.7874V34.8511H55.7875ZM55.7875 29.8724H2.68099V27.6597H9.3193C9.93036 27.6597 10.4257 27.1644 10.4257 26.5534C10.4257 25.9423 9.93036 25.447 9.3193 25.447H2.68099V6.08513C2.68099 3.94993 4.41808 2.21273 6.55339 2.21273H51.9151C54.0503 2.21273 55.7875 3.94981 55.7875 6.08513V29.8724Z" fill="#002046"/>
              </svg>
              , label: 'תמיכה מרחוק' ,link:"/support"},
              
      
        { icon: <svg style={{ marginLeft:"12px" }}width="35" height="35"  onClick={()=>  window.open(`localhost:3000`)} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5247 10.5C10.5247 8.567 12.0917 7 14.0246 7C15.9576 7 17.5246 8.56704 17.5246 10.5C17.5246 12.433 15.9576 14 14.0246 14C12.0916 14 10.5247 12.433 10.5247 10.5Z" fill="#00BCFF"/>
        <path d="M3.52466 17.4999C3.52466 15.5669 5.09166 13.9999 7.02464 13.9999L8.88983 13.0151L10.5246 13.9999V17.4999C10.5246 19.4329 8.95763 20.9999 7.02464 20.9999C5.09166 20.9999 3.52466 19.4329 3.52466 17.4999Z" fill="#00CF7F"/>
        <path d="M10.5246 0L8.62012 3.30873L10.5246 6.99997H13.9755C15.9086 6.99997 17.4755 5.43297 17.4755 3.49999C17.4755 1.567 15.9085 0 13.9755 0H10.5246Z" fill="#FF7361"/>
        <path d="M3.47546 3.49999C3.47546 5.43297 5.04247 6.99997 6.97545 6.99997L8.83211 7.71709L10.5245 6.99997V0H6.97541C5.04247 0 3.47546 1.567 3.47546 3.49999Z" fill="#FF4D12"/>
        <path d="M3.52466 10.5C3.52466 12.4331 5.09166 14 7.02464 14H10.5246V7H7.02464C5.09166 7 3.52466 8.56704 3.52466 10.5Z" fill="#B659FF"/>
        </svg>, label: 'FIGMA עיצוב ב',link:selectedProject.urlFigma },
      ];
      
      

  return (
    <div>
      <div className='div'>
      <div>
      < Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 3 }}>
      {projects.length>1 &&(
      <FormControl variant="outlined" sx={{ minWidth: 300 }}>
        <InputLabel id="project-select-label">בחר פרויקט</InputLabel>
        <Select
          labelId="project-select-label"
          id="project-select"
          value={selectedProject ? selectedProject.projectId : ''}
          onChange={(event: SelectChangeEvent<string>) => handleChangeProject(event)}
          label="בחר פרויקט"
        >
          <MenuItem value="" disabled>בחר פרויקט</MenuItem>
          {projects.map((project) => (
            <MenuItem key={project.projectId} value={project.projectId}>
              {project.businessName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      )}
      {selectedProject && selectedProject.endDate && (
        // <Box sx={{ mt: 3 }}>
          <MoreStatus project={selectedProject} />
        // </Box>
      )}
    </Box>
</div>
<div style={{ position: "fixed", bottom: 0, right: 0, width: "400px" ,zIndex:"1000"}}> <MyChatBot2 /></div>
      {/* {selectedProject && selectedProject.endDate && <MoreStatus project={selectedProject}></MoreStatus>} */}
      </div>
      <Box sx={{ padding: 3, backgroundColor: '#f4f7fc', marginTop: 2, marginRight: "6%", marginLeft: "6%" }}>
      <Typography
      sx={{
        marginBottom: 2,
        textAlign: 'right',
        fontSize: '28px',
        fontWeight: 700,
        fontFamily: 'CustomFont',
        lineHeight: '48px',
        '@media (max-width: 600px)': {
          marginBottom:"30px",
          fontSize: '22px !important', /* גודל קטן יותר למובייל */
        },
      }}
    >
      ?פעולות מהירות - מה ברצונכם לעשות היום
    </Typography>
        <Grid container spacing={2}  marginTop={"5%"} marginBottom={"5%"} >
          {actions.map((action, index) => (
            <Grid item xs={12} sm={6} md={3} key={index} >
            <Link to={action.link} style={{}}>
              <Card  className='card' sx={{ borderRadius: '15px' }} >
                <IconButton>
                  {action.icon}
                </IconButton>
                <CardContent >
                <Typography variant="body1" sx={{ display: 'inline-block', marginLeft: '8px' ,fontWeight: 700,fontSize:"22px"}} className='label'>
                <GoArrowDownLeft className='arrow' />
                {action.label}
                  </Typography>
                </CardContent>
              </Card>
               </Link>

            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};




export default QuickActions;